import React from "react";
import { Modal } from "antd";

interface MyComponent {
  openDemoModal: any;
  closeDemoModal: () => void;
}

const DemoModal: React.FC<MyComponent> = ({
  openDemoModal,
  closeDemoModal,
}) => {
  return (
    <Modal
      title="Book a Meeting"
      open={openDemoModal}
      onCancel={closeDemoModal}
      footer={false}
      width={900}
    >
      <iframe
        title={"hubspot"}
        style={{
          width: "100%",
          height: "695px",
          border: "none",
        }}
        src={"https://meetings.hubspot.com/diwakar1?embed=true"}
      />
      
    </Modal>
  );
};
export default DemoModal;
