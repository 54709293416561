import React, { useState } from "react";
import { Layout } from "antd";
import Logo from "./elements/Logo";
import ActionStrip from "./elements/ActionStrip";
import MenuStrip from "./elements/MenuStrip";

import HeaderJson from "./header.json";
import DemoModal from "./elements/DemoModal";

interface MyComponent {
  navigation: any;
  hiringLink: any;
  demoLink: any;
  closeDemoModal: () => void;
  handleMenuOpen: () => void;
}

const HeaderView: React.FC = () => {
  const [activeHamburger, setActiveHamburger] = useState<boolean>(false);
  const [openDemoModal, setOpenDemoModal] = useState<boolean>(false);

  const handleMenuOpen = () => {
    setActiveHamburger(!activeHamburger);
  };
  const closeDemoModal = () => {
    setOpenDemoModal(!openDemoModal);
  };

  return (
    <Layout.Header
      className={
        activeHamburger === true ? "mainHeader menuOpen" : "mainHeader"
      }
    >
      <div className="container">
        <Logo />
        <div className="headerNavSection">
          <ActionStrip
            actionLink={HeaderJson.action_link}
            socialLink={HeaderJson.social_media}
            help_support={HeaderJson.help_support}
          />
          <MenuStrip
            navigation={HeaderJson.menu}
            hiringLink={HeaderJson.hiring}
            demoLink={HeaderJson.demo_link}
            closeDemoModal={closeDemoModal}
            handleMenuOpen={handleMenuOpen}
          />

          <div
            className={activeHamburger === true ? "is-active" : ""}
            id="hamburgerIcon"
            onClick={handleMenuOpen}
          >
            <span className="line"></span>
            <span className="line"></span>
            <span className="line"></span>
          </div>
        </div>
      </div>
      <DemoModal
        openDemoModal={openDemoModal}
        closeDemoModal={closeDemoModal}
      />
    </Layout.Header>
  );
};

export default HeaderView;
