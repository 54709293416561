import { Card, Col, Row } from "antd"
import { Pagination } from 'antd';
import moment from "moment"
import "../../../assets/styles/modules/blog.less"
import SVGIcon from "../../../utils/SVGIcon";
import { splitIntoDot } from "../../../config/Global";
import InnerBanner from "../../../components/Sections/InnerBanner";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBlogData, setPagination } from "./Utils/BlogSlice";
import { AppDispatch, RootState } from "../../../store/app";
import { Link } from "react-router-dom";
import PageSpinner from "../../../components/PageSpinner/PageSpinner";
import type { PaginationProps } from 'antd';


const Index = () => {
    const { Meta } = Card;
    const dispatch: AppDispatch = useDispatch()
    const [blogData, setBlogData] = useState<any>([])
    const [loading, setLoading] = useState(true);
    const page = useSelector((state: RootState) => state.BLOG.pagination)

    const handleBlogFetch = useCallback((page: any, per_page: any) => {
        const pagePayload = {
            page: page ?? 1,
            per_page: per_page ?? 9
        };
       
        dispatch(getBlogData(pagePayload))
            .then((data: any) => {
                setBlogData(data);
                setLoading(false);
            })
            .catch(() => setLoading(false))
            .finally(() => setLoading(false));
    }, [dispatch]);


    useEffect(() => {
        handleBlogFetch(1, 9)
    }, [handleBlogFetch])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, [page]);


    const onPageChange: PaginationProps['onChange'] = (pageNumber: any) => {
      
        const page = {
            page: pageNumber,
            per_page: 9
        }
        dispatch(setPagination(page))

        handleBlogFetch(pageNumber, 9)
        
    };

    return !loading ? (
        <section id="BlogPage">
            <InnerBanner title="Resources" />
            <div className="container">
                <Row gutter={[30, 30]} className="blogContent">
                    {blogData?.blogs?.length > 0 ?
                        blogData?.blogs.map((item: any, index: number) => {
                            return (
                                <Col key={index} xs={24} md={12} lg={8}>
                                    <Card
                                        className="slideItem"
                                        hoverable
                                        bordered={false}
                                        cover={
                                            <>
                                                {item?.publish_date && <span className="date">{moment(item?.publish_date).format("MMM, DD YYYY")}</span>}
                                                {item?.image_url && <img src={item?.image_url} alt={item?.image_url} />}
                                            </>
                                        }
                                    >
                                        <Meta title={<Link to={item.slug} title={item?.title}>{splitIntoDot(item?.title, 60)}</Link>} description={item.short_description ? splitIntoDot(item.short_description, 80) : "N/A"} />
                                        <Link to={item.slug} rel="noreferrer" title="Read More" className="link">
                                            Read More <SVGIcon icon="rightTopArrow" />
                                        </Link>
                                    </Card>
                                </Col>
                            )
                        })
                        :
                        <Col xs={24} className="text-center">
                            <h2>No Current Blog Post</h2>
                            <p>We appreciate your interest in working with us. Unfortunately, there are no blog post available at this time. Please check back later for any updates.</p>
                        </Col>

                    }
                    {blogData?.blogs?.length > 0 &&
                        <Pagination
                            showSizeChanger={false}
                            responsive={true}
                            showLessItems={true}
                            defaultCurrent={1}
                            defaultPageSize={9}
                            total={blogData.total_count}
                            // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                            onChange={onPageChange}
                        />
                    }
                </Row>
            </div>
        </section>
    ) : <PageSpinner />
}

export default Index